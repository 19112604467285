import { useEffect, useRef } from 'react';
/**
 * Uses sound from **public** folder only.
 * @param source mp3 file name
 * @returns
 */
export const useSound = (source) => {
    const audio = useRef(new Audio(undefined));
    useEffect(() => {
        if (source)
            audio.current = new Audio(`warehouse/${source}`);
    }, [source]);
    const play = () => {
        try {
            audio.current.play();
        }
        catch (error) {
            console.log('error', error);
        }
    };
    return [play];
};
