import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { getConfirmFieldsFromReceiptItem, getReceiptItemAvailableQuantity } from 'warehouse/modules/receipt/helpers';
import { confirmReceiptItem } from './confirmReceiptItem';
import { createAppAsyncThunk } from 'warehouse/helpers';
import { playWarehouseSound } from 'warehouse/hooks';
import { receiptActions } from '../receipt.state';
import { receiptApprovementSnackbarDuration } from 'warehouse/modules/receipt/constants';
const tryApproveReceiptItem = createAppAsyncThunk('receipt/tryApproveReceiptItem', async (receiptItem, { getState, dispatch, rejectWithValue }) => {
    if (!receiptItem) {
        const searchTerm = getState().receipt.sharedArticleLocalSearchTerm;
        if (searchTerm.trim().length === 0)
            return;
        // A single snackbar is allowed at one moment. Manually closing existed one, since
        closeSnackbar();
        playWarehouseSound('error');
        enqueueSnackbar({
            key: searchTerm,
            preventDuplicate: true,
            variant: 'receiptSubmissionError',
            article: searchTerm,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            autoHideDuration: receiptApprovementSnackbarDuration,
        });
        dispatch(receiptActions.setSharedArticleLocalSearchTerm(''));
        return;
    }
    const availableQuantity = getReceiptItemAvailableQuantity(receiptItem);
    if (availableQuantity < 1) {
        playWarehouseSound('full');
        return;
    }
    const receiptItemConfirm = getConfirmFieldsFromReceiptItem(receiptItem);
    if (availableQuantity === 1) {
        const confirmItem = {
            ...receiptItemConfirm,
            quantity: 1,
        };
        dispatch(confirmReceiptItem(confirmItem));
        return;
    }
    dispatch(receiptActions.openApproveReceiptItemDialog({ receiptItemConfirm: receiptItemConfirm, availableQuantity }));
});
export { tryApproveReceiptItem };
