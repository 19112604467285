import { baseApiQuery, providesList } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { placementActions } from './placement.state';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { t } from 'shared/translations';
export const placementApi = createApi({
    reducerPath: 'api/placement',
    tagTypes: ['Placement', 'Cells'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getReceiptedPlacementItems: builder.query({
            query: (body) => ({
                method: 'POST',
                url: 'placement',
                body: { ...body, isPlaced: false },
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const result = await queryFulfilled;
                    dispatch(placementActions.setReceiptedItemCount(result.data?.length ?? 0));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.placement.items.request.failed'), error }));
                }
            },
            providesTags: (result) => providesList(result, 'Placement', 'stickerId'),
        }),
        getPlacedPlacementItems: builder.query({
            query: (body) => ({
                method: 'POST',
                url: 'placement',
                body: { ...body, isPlaced: true },
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const result = await queryFulfilled;
                    dispatch(placementActions.setPlacedItemCount(result.data?.length ?? 0));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.placement.items.request.failed'), error }));
                }
            },
            providesTags: (result) => providesList(result, 'Placement', 'stickerId'),
        }),
        getPlacementCells: builder.query({
            query: () => ({
                method: 'GET',
                url: 'placement/cells',
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    // dispatch(placementActions.setPlacedItemCount(result.data?.length ?? 0));
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.placement.cells.request.failed'), error }));
                }
            },
            providesTags: (result) => [{ type: 'Cells', id: 'LIST' }],
        }),
        blockUnblockSticker: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'sticker/block',
                body,
            }),
            invalidatesTags: (result, error, { stickerId }) => (error ? [] : [{ type: 'Placement', id: stickerId }]),
        }),
        assignStickerPlace: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'placement/place',
                body,
            }),
            onQueryStarted: async ({ stickerId }, { queryFulfilled, dispatch }) => {
                await queryFulfilled;
                // On mutation request is finished, run invalidation for 3 query requests without await that blocks the thread,
                // since `invalidatesTags` forces to await main request along with all invalidation requests.
                dispatch(placementApi.util.invalidateTags([{ type: 'Placement', id: stickerId }, { type: 'Cells' }]));
            },
            // invalidatesTags: (result, error, { stickerId }) => (error ? [] : [{ type: 'Placement', id: stickerId }, { type: 'Cells' }]),
        }),
    }),
});
export const { useGetReceiptedPlacementItemsQuery, useGetPlacedPlacementItemsQuery, useGetPlacementCellsQuery, useBlockUnblockStickerMutation, useAssignStickerPlaceMutation, } = placementApi;
