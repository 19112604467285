import error from 'warehouse/media/error.mp3';
import full from 'warehouse/media/full.mp3';
import { useRef } from 'react';
/**
 * Uses **preloaded** sound from **warehouse/media** folder.
 * @returns
 */
export const useWarehouseSound = () => {
    const audio = useRef(new Audio(undefined));
    const play = (sound) => {
        try {
            audio.current = new Audio(sound === 'full' ? full : sound === 'error' ? error : new Audio(undefined));
            audio.current.play();
        }
        catch (error) {
            console.warn('play sound error', error);
        }
    };
    return [play];
};
// TODO: extract out of hooks folder later. (mb to helpers folder)
export const playWarehouseSound = (sound) => {
    const audio = new Audio(sound === 'full' ? full : sound === 'error' ? error : new Audio(undefined));
    try {
        audio.play();
    }
    catch (error) {
        console.warn('play sound error', error);
    }
};
