import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { createAppAsyncThunk } from 'warehouse/helpers';
import { getErrorFromException } from 'core/utils/errorHandling';
import { receiptActions } from '../receipt.state';
import { receiptApi } from '../receipt.api';
import { receiptApprovementSnackbarDuration } from 'warehouse/modules/receipt/constants';
import { t } from 'shared/translations';
const confirmReceiptItem = createAppAsyncThunk('receipt/confirmReceiptItem', async (receiptItem, { getState, dispatch, rejectWithValue }) => {
    try {
        // A single snackbar is allowed at one moment.
        closeSnackbar();
        await dispatch(receiptApi.endpoints.confirmReceiptItem.initiate(receiptItem)).unwrap();
        enqueueSnackbar({
            // Should not prevent duplicate since another quantity of the same article may be confirmed.
            // key: receiptItem.receiptItemId,
            // preventDuplicate: true,
            variant: receiptItem.isBlocked ? 'receiptBlockSuccess' : 'receiptSubmissionSuccess',
            brand: receiptItem.brand,
            article: receiptItem.article,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            autoHideDuration: receiptApprovementSnackbarDuration,
        });
        // Clear search text field.
        dispatch(receiptActions.setSharedArticleLocalSearchTerm(''));
    }
    catch (error) {
        const errorData = error.data;
        if (errorData?.Message?.includes('The received quantity more that available')) {
            // TODO: later. Get rid of this when new backend will be ready.
            enqueueSnackbar({
                variant: 'error',
                header: receiptItem.isBlocked ? t('receipt.item.block.failed') : t('receipt.item.reception.failed'),
                body: receiptItem.isBlocked ? t('blocked.quantity.more.than.available') : t('received.quantity.more.than.available'),
                persist: false,
            });
        }
        else {
            enqueueSnackbar({
                variant: 'error',
                header: receiptItem.isBlocked ? t('receipt.item.block.failed') : t('receipt.item.reception.failed'),
                body: getErrorFromException(error),
                persist: true,
            });
        }
        console.error(`Receipt item ${receiptItem.isBlocked ? 'block' : 'confirm'} error:`, error);
        if (error instanceof Error) {
            return rejectWithValue(`Cannot confirm receipt item: ${error.message}`);
        }
        return rejectWithValue('Server unexpected error');
    }
});
export { confirmReceiptItem };
