import * as signalR from '@microsoft/signalr';
// TODO: to delete all unnecessary comments after finish
// TODO: to delete
// const URL = process.env.HUB_ADDRESS ?? 'https://localhost:5001/hub'; //or whatever your backend port is
// const signalrHubUrl = 'http://130.193.49.156:8092';
const signalrHubUrl = 'http://130.193.49.156:8093';
// .withUrl(`${signalrEndpointUrl}/${connectionHub}`, options)
/**
 * *withAutomaticReconnect* won't configure the *HubConnection* to retry initial start failures, so start failures need to be handled manually.
 *
 * @param connection
 */
const startSignalrConnection = async (connection) => {
    try {
        await connection.start();
        console.assert(connection.state === signalR.HubConnectionState.Connected);
        console.log('SignalR connection established');
    }
    catch (error) {
        console.assert(connection.state === signalR.HubConnectionState.Disconnected);
        console.error('SignalR Connection Error: ', error);
        // TODO: to return!
        // setTimeout(() => startSignalrConnection(connection), 5000);
    }
};
/** Creates a singleton that stores all connections to signalR hubs. */
class Connector {
    // class Connector<T> {
    connection;
    // TODO: ! instances: Connector[]
    static instance;
    events;
    // public addEventListener: (eventName: string, listener: (event: any) => void) => void;
    // public addEventListener: <T>(eventName: T, listener: (event: any) => void) => void;
    // public addEventListener: <T extends receiptHubSignalrEventNames | stockHubSignalrEventNames>(
    addEventListener;
    // constructor(connectionHubName: string) {
    constructor(connectionHubName) {
        this.connection = new signalR.HubConnectionBuilder().withUrl(`${signalrHubUrl}/${connectionHubName}`).withAutomaticReconnect().build();
        // this.connection.start().catch((error) => document.write(error));
        startSignalrConnection(this.connection);
        this.events = (onMessageReceived) => {
            this.connection.on('messageReceived', (username, message) => {
                onMessageReceived(username, message);
            });
        };
        this.addEventListener = (eventName, listener) => {
            this.connection.on(eventName, (arg) => {
                listener(arg);
            });
        };
        // TODO: handle?
        this.connection.onclose(async (error) => {
            // TODO: to delete
            console.log('signalR connection onclose, error: ', error);
            console.assert(this.connection.state === signalR.HubConnectionState.Disconnected);
            // TODO:
            // enqueueSnack for disconnected and after snack with connected
            // li.textContent = `Connection closed due to error "${error}". Try refreshing this page to restart the connection.`;
            await startSignalrConnection(this.connection);
        });
    }
    // public newMessage = (messages: string) => {
    // 	this.connection.send('newMessage', 'foo', messages).then((x) => console.log('sent'));
    // };
    // public static getInstance(connectionHubName: string): Connector {
    // public static getInstance(connectionHubName: SignalrConnectionHub): Connector {
    static getInstance(connectionHubName) {
        if (!Connector.instance)
            Connector.instance = new Connector(connectionHubName);
        return Connector.instance;
    }
}
export const signalrHubConnector = Connector.getInstance;
//  export default Connector.getInstance;
//  export const signalrHubConnector
