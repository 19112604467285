import { createSharedStockSlice, sharedStockInitialState } from 'shared/modules/stock/business';
const initialState = {
    searchTerm: '',
};
const stockSlice = createSharedStockSlice({
    name: 'stock',
    initialState: { ...sharedStockInitialState, ...initialState },
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
});
export const { actions: stockActions, reducer: stockStateReducer } = stockSlice;
