import { SignalrConnectionHub, signalrHubConnector } from 'warehouse/signalR';
import { baseApiQuery, blobResponseHandler, downloadBlob, providesList } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
const api = createApi({
    reducerPath: 'api/receipt',
    tagTypes: ['Invoices', 'ReceiptItems', 'StickerItems'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getInvoices: builder.query({
            query: (body) => ({
                url: 'receipt/invoice',
                method: 'POST',
                body,
            }),
            transformResponse: (response) => response.map((invoice) => ({
                ...invoice,
                invoiceDate: new Date(invoice.invoiceDate),
            })),
            providesTags: (result) => providesList(result, 'Invoices', 'invoiceId'),
            onCacheEntryAdded: async (arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                const { addEventListener, events } = signalrHubConnector(SignalrConnectionHub.Receipt);
                // create a websocket connection when the cache subscription starts
                // const ws = new WebSocket('ws://localhost:8080')
                try {
                    // wait for the initial query to resolve before proceeding
                    await cacheDataLoaded;
                    // when data is received from the socket connection to the server,
                    // if it is a message and for the appropriate channel,
                    // update our query result with the received message
                    // const listener = (event: MessageEvent) => {
                    // const listener = (event: StickerItemSocketMessage) => {
                    const listener = (event) => {
                        // TODO: to delete
                        console.log('in listener, event:', event);
                        const data = JSON.parse(event.data);
                        // TODO: to delete
                        console.log('in listener, event.data:', data);
                        console.log('in listener, arg:', arg);
                        const message = data;
                        // TODO: to return the check
                        // if (!isMessage(data) || data.channel !== arg) return
                        updateCachedData((draft) => {
                            // TODO: to delete
                            console.log('getInvoices, onCacheEntryAdded in updateCachedData, draft:', draft);
                            console.log('getInvoices, onCacheEntryAdded in updateCachedData, message:', message);
                            const invoiceIndex = draft.findIndex((invoice) => invoice.invoiceId === message.invoiceId);
                            if (invoiceIndex === -1)
                                return;
                            draft[invoiceIndex].receiptedQuantity = message.invoiceReceiptedQuantity;
                        });
                    };
                    // ws.addEventListener('message', listener)
                    addEventListener('CreateOrDeleteStickerItem', listener);
                    // addEventListener<SignalrConnectionHub.Stock>('StockBalanceChange', listener);
                }
                catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                // cacheEntryRemoved will resolve when the cache subscription is no longer active
                await cacheEntryRemoved;
            },
        }),
        getReceiptItems: builder.query({
            query: ({ blocked: IsBlocked, invoiceId }) => ({
                // TODO: later (when new backend is done) - we actually need only InvoiceInfo fields but got all Invoice fields.
                url: 'receipt/receiptItems',
                method: 'POST',
                // Different queries: for Verification tab - only IsBlocked flag is valid; for Invoice receiptItems - only invoiceId.
                body: { ...(IsBlocked !== undefined && { IsBlocked }), ...(invoiceId !== undefined && { invoiceId }) },
            }),
            transformResponse: (response) => ({
                invoice: {
                    ...response.invoice,
                    invoiceDate: new Date(response.invoice.invoiceDate),
                },
                receiptItems: response.receiptItems.map((item) => ({
                    ...item,
                    invoice: { ...item.invoice, invoiceDate: new Date(item.invoice.invoiceDate) },
                })),
            }),
            // providesTags: (result) =>
            // 	result
            // 		? [{ type: 'ReceiptItems', id: 'LIST' }, ...result.receiptItems.map((item) => ({ type: 'ReceiptItems' as const, id: item.receiptItemId }))]
            // 		: [{ type: 'ReceiptItems', id: 'LIST' }],
            providesTags: (result) => providesList(result?.receiptItems, 'ReceiptItems', 'receiptItemId'),
            // onCacheEntryAdded: (arg,{ updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
            // 	// create a websocket connection when the cache subscription starts
            //   const ws = new WebSocket('ws://localhost:8080')
            //   try {
            //     // wait for the initial query to resolve before proceeding
            //     await cacheDataLoaded
            //     // when data is received from the socket connection to the server,
            //     // if it is a message and for the appropriate channel,
            //     // update our query result with the received message
            //     const listener = (event: MessageEvent) => {
            //       const data = JSON.parse(event.data)
            //       if (!isMessage(data) || data.channel !== arg) return
            //       updateCachedData((draft) => {
            //         draft.push(data)
            //       })
            //     }
            //     ws.addEventListener('message', listener)
            //   } catch {
            //     // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
            //     // in which case `cacheDataLoaded` will throw
            //   }
            //   // cacheEntryRemoved will resolve when the cache subscription is no longer active
            //   await cacheEntryRemoved
            //   // perform cleanup steps once the `cacheEntryRemoved` promise resolves
            //   ws.close()
            // }
            onCacheEntryAdded: async (arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                // const { newMessage, events } = signalrHubConnector(SignalrRemoteHubs.Receipt);
                const { addEventListener, events } = signalrHubConnector(SignalrConnectionHub.Receipt);
                // create a websocket connection when the cache subscription starts
                // const ws = new WebSocket('ws://localhost:8080')
                try {
                    // wait for the initial query to resolve before proceeding
                    await cacheDataLoaded;
                    // when data is received from the socket connection to the server,
                    // if it is a message and for the appropriate channel,
                    // update our query result with the received message
                    // const listener = (event: MessageEvent) => {
                    // const listener = (event: StickerItemSocketMessage) => {
                    const listener = (event) => {
                        // TODO: to delete
                        console.log('in listener, event:', event);
                        const data = JSON.parse(event.data);
                        // TODO: to delete
                        console.log('in listener, event.data:', data);
                        console.log('in listener, arg:', arg);
                        // TODO: to return the check
                        // if (!isMessage(data) || data.channel !== arg) return
                        const message = data;
                        updateCachedData((draft) => {
                            //draft.push(data)
                            // TODO: to delete
                            console.log('getReceiptItems, onCacheEntryAdded in updateCachedData, draft:', draft);
                            console.log('getReceiptItems, onCacheEntryAdded in updateCachedData, message:', message);
                            const receiptItemIndex = draft.receiptItems.findIndex((item) => item.receiptItemId === message.receiptItemId);
                            if (receiptItemIndex === -1)
                                return;
                            draft.receiptItems[receiptItemIndex].receiptedQuantity = message.itemReceiptedQuantity;
                            draft.receiptItems[receiptItemIndex].blockedQuantity = message.itemBlockedQuantity;
                            // TODO:
                            // If the Verification tab is active and therefore receiptItems endpoint with IsBlocked flag is used,
                            // we should probably remove receiptItem with not found receiptItemIndex, because it probably has 0 available items and
                            // does not exist on query result anymore.
                            // We should even try to separate current endpoint by 'IsBlocked' (for Verification tab) and 'invoiceId' for Invoice receiptItems
                            // to have a possibility to react on socket updates in different ways (to remove or not to remove receiptItem).
                            // TODO: check for draft.invoice is valid (later). May be it is not even necessary
                            // const itemIndex = draft.receiptItems.findIndex((item) => item.receiptItemId === data.receiptItemId);
                            // if (itemIndex !== -1) draft.receiptItems[itemIndex].receiptedQuantity = data.receiptItemQuantity;
                        });
                    };
                    // ws.addEventListener('message', listener)
                    addEventListener('CreateOrDeleteStickerItem', listener);
                    // addEventListener<SignalrConnectionHub.Stock>('StockBalanceChange', listener);
                }
                catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                // cacheEntryRemoved will resolve when the cache subscription is no longer active
                await cacheEntryRemoved;
                // perform cleanup steps once the `cacheEntryRemoved` promise resolves
                // ws.close();
                // TODO: do we need to close socket connection 'ws.close()' ?
            },
        }),
        // TODO: to delete later. Without source list info.
        // confirmReceiptItem: builder.mutation<void, ReceiptItemConfirm | ReceiptItemBlock>({
        // 	query: (body) => ({
        // 		method: 'POST',
        // 		url: 'receipt/createItem',
        // 		body,
        // 	}),
        // 	invalidatesTags: (result, error, { invoiceId, receiptItemId }) =>
        // 		// 'result' is null in case of successful POST, so 'error' must be checked.
        // 		error
        // 			? []
        // 			: [
        // 					{ type: 'Invoices', id: invoiceId },
        // 					{ type: 'ReceiptItems', id: receiptItemId },
        // 					{ type: 'StickerItems', id: 'LIST' },
        // 			  ],
        // }),
        confirmReceiptItem: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'receipt/createItem',
                body,
            }),
            invalidatesTags: (result, error, { invoiceId, receiptItemId }) => 
            // Prevents update tags on error.
            // 'result' is null in case of successful POST, so 'error' must be checked.
            error
                ? []
                : [
                    { type: 'Invoices', id: invoiceId },
                    // 'ReceiptItems' invalidation is not to be used here, we manually update items in onQueryStarted with pessimistic update.
                    // { type: 'ReceiptItems', id: receiptItemId },
                    { type: 'StickerItems', id: 'LIST' },
                ],
            onQueryStarted: async (request, { dispatch, queryFulfilled, getState }) => {
                const ownerLocation = getState().receipt.ownerLocation;
                const { receiptItemId, quantity, isBlocked, invoiceId } = request;
                const receiptItemsRequestPayload = {
                    ...(ownerLocation === 'invoice' ? { invoiceId } : { blocked: ownerLocation !== 'verification' }),
                };
                // const patchResult = dispatch(
                // 	// receiptApi.util.updateQueryData('getPost', id, (draft) => {
                // 	receiptApi.util.updateQueryData('getReceiptItems', receiptItemsRequestPayload, (draft) => {
                // 		console.log('draft:', draft);
                // 		// Object.assign(draft, patch);
                // 	})
                // );
                try {
                    await queryFulfilled;
                    // `isBlocked === true` - blocking, `isBlocked === false` - reception,
                    const patchResult = dispatch(api.util.updateQueryData('getReceiptItems', receiptItemsRequestPayload, (draft) => {
                        // TODO: to delete
                        // console.log('draft:', current(draft));
                        const patch = {
                            ...draft,
                            // Data from invoice is used in Invoice progress, so it should also be updated.
                            invoice: {
                                ...draft.invoice,
                                ...(isBlocked
                                    ? { blockedQuantity: draft.invoice.blockedQuantity + quantity }
                                    : { receiptedQuantity: draft.invoice.receiptedQuantity + quantity }),
                            },
                            receiptItems: draft.receiptItems.map((receiptItem) => receiptItem.receiptItemId === receiptItemId
                                ? {
                                    ...receiptItem,
                                    ...(isBlocked
                                        ? { blockedQuantity: receiptItem.blockedQuantity + quantity }
                                        : { receiptedQuantity: receiptItem.receiptedQuantity + quantity }),
                                }
                                : receiptItem),
                        };
                        Object.assign(draft, patch);
                    }));
                }
                catch {
                    // mb move snackbar to here?
                    // TODO: to delete this comments later. This is to be used with optimistic update.
                    // patchResult.undo();
                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
        }),
        // TODO: to delete. Good working solution with invalidateTags for receiptItems query.
        // revokeReceiptItem: builder.mutation<void, ReceiptItemRevoke>({
        // 	query: (body) => ({
        // 		method: 'POST',
        // 		url: 'receipt/deleteItem',
        // 		body,
        // 	}),
        // 	invalidatesTags: (result, error, { invoiceId, receiptItemId, stickers, isBlocked }) =>
        // 		// Prevents update tags on error.
        // 		// 'result' is null in case of successful POST, so 'error' must checked instead of 'result'.
        // 		error
        // 			? []
        // 			: [
        // 					{ type: 'Invoices', id: invoiceId },
        // 					{ type: 'ReceiptItems', id: receiptItemId },
        // 					{ type: 'StickerItems', id: 'LIST' },
        // 			  ],
        // }),
        revokeReceiptItem: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'receipt/deleteItem',
                body,
            }),
            invalidatesTags: (result, error, { invoiceId, receiptItemId, stickers, isBlocked }) => 
            // Prevents update tags on error.
            // 'result' is null in case of successful POST, so 'error' must be checked instead of 'result'.
            error
                ? []
                : [
                    { type: 'Invoices', id: invoiceId },
                    // 'ReceiptItems' invalidation is not to be used here, we manually update items in onQueryStarted with pessimistic update.
                    // { type: 'ReceiptItems', id: receiptItemId },
                    { type: 'StickerItems', id: 'LIST' },
                ],
            onQueryStarted: async (request, { dispatch, queryFulfilled, getState }) => {
                const ownerLocation = getState().receipt.ownerLocation;
                const { receiptItemId, stickers, isBlocked, invoiceId } = request;
                const receiptItemsRequestPayload = {
                    ...(ownerLocation === 'invoice' ? { invoiceId } : { blocked: ownerLocation !== 'verification' }),
                };
                try {
                    await queryFulfilled;
                    // `isBlocked === true` - unblocking, `isBlocked === false` - revoking,
                    const patchResult = dispatch(api.util.updateQueryData('getReceiptItems', receiptItemsRequestPayload, (draft) => {
                        const canceledQuantity = stickers.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0);
                        const patch = {
                            ...draft,
                            // Data from invoice is used in Invoice progress, so it should also be updated.
                            invoice: {
                                ...draft.invoice,
                                ...(isBlocked
                                    ? { blockedQuantity: draft.invoice.blockedQuantity - canceledQuantity }
                                    : { receiptedQuantity: draft.invoice.receiptedQuantity - canceledQuantity }),
                            },
                            receiptItems: draft.receiptItems.map((receiptItem) => receiptItem.receiptItemId === receiptItemId
                                ? {
                                    ...receiptItem,
                                    ...(isBlocked
                                        ? { blockedQuantity: receiptItem.blockedQuantity - canceledQuantity }
                                        : { receiptedQuantity: receiptItem.receiptedQuantity - canceledQuantity }),
                                }
                                : receiptItem),
                        };
                        Object.assign(draft, patch);
                    }));
                }
                catch {
                    // mb move snackbar to here?
                }
            },
        }),
        getStickerItems: builder.query({
            query: (body) => ({
                method: 'POST',
                url: 'receipt/stickerItems',
                body,
            }),
            transformResponse: (response) => response.map((stickerItem) => ({
                ...stickerItem,
                createDate: new Date(stickerItem.createDate),
            })),
            providesTags: (result) => [{ type: 'StickerItems', id: 'LIST' }],
        }),
        exportInvoiceToExcel: builder.mutation({
            queryFn: async ({ receiptItems }, api, extraOptions, baseQuery) => downloadBlob({
                queryResult: await baseQuery({
                    method: 'POST',
                    cache: 'no-cache',
                    url: 'receipt/xlsx',
                    body: receiptItems,
                    responseHandler: blobResponseHandler,
                }),
                fileName: `${'receipts'}.xlsx`,
            }),
        }),
    }),
});
export const { useGetInvoicesQuery, useGetReceiptItemsQuery, useConfirmReceiptItemMutation, useGetStickerItemsQuery, useRevokeReceiptItemMutation, useExportInvoiceToExcelMutation, } = api;
export { api as receiptApi };
