import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { placementApi, placementStateReducer } from './modules/placement/business';
import { authApi } from 'shared/modules/auth/business';
import { isDevelopmentMode } from 'shared/helpers';
import { layoutReducer } from 'shared/modules/layout/business';
import { receiptApi } from 'warehouse/modules/receipt/business';
import { receiptStateReducer } from './modules/receipt/business/';
import { shipmentApi } from 'shared/modules/shipment/business';
import { stockApi } from 'warehouse/modules/stock/business';
import { stockStateReducer } from 'warehouse/modules/stock/business';
// import { signalrMiddleware } from './signalrMiddleware';
const rootReducer = combineReducers({
    layout: layoutReducer,
    stock: stockStateReducer,
    receipt: receiptStateReducer,
    placement: placementStateReducer,
    [authApi.reducerPath]: authApi.reducer,
    [stockApi.reducerPath]: stockApi.reducer,
    [receiptApi.reducerPath]: receiptApi.reducer,
    [placementApi.reducerPath]: placementApi.reducer,
    [shipmentApi.reducerPath]: shipmentApi.reducer,
});
// From https://redux-toolkit.js.org/api/getDefaultMiddleware.
// It is preferable to use the chainable .concat(...) and .prepend(...) methods of the returned Tuple instead of the array spread
// operator, as the latter can lose valuable TS type information under some circumstances.
export const store = configureStore({
    reducer: rootReducer,
    devTools: isDevelopmentMode(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
        // .prepend(signalrMiddleware)
        .concat(authApi.middleware, stockApi.middleware, receiptApi.middleware, placementApi.middleware, shipmentApi.middleware),
});
if (isDevelopmentMode() && module.hot) {
    module.hot.accept(() => store.replaceReducer(rootReducer));
}
