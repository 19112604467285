import { StickerState } from 'shared/models';
import { t } from 'shared/translations';
export const getStickerStateName = (stickerState) => {
    switch (stickerState) {
        case StickerState.Receipted:
            return t('receipted.label');
        case StickerState.Placed:
            return t('placed.label');
        case StickerState.Picked:
            return t('picked.label');
        case StickerState.Packed:
            return t('packed.label');
        case StickerState.Shipped:
            return t('shipped.label');
        default:
            return 'Unknown receipt status';
    }
};
export const isStickerStateRevokable = (stickerState) => stickerState < StickerState.Packed;
