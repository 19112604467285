import { BlockReason } from 'shared/models';
import { t } from 'shared/translations';
export const getBlockReasonLabel = (blockReason) => blockReason === BlockReason.Defect
    ? t('defective')
    : blockReason === BlockReason.Counterfeit
        ? t('counterfeit')
        : blockReason === BlockReason.Undersupply
            ? t('undersupply')
            : blockReason === BlockReason.Loss
                ? t('loss')
                : blockReason === BlockReason.Refusal
                    ? t('refusal')
                    : blockReason === BlockReason.Refund
                        ? t('refund')
                        : undefined;
