import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { getReceiptItemAvailableQuantity } from 'warehouse/modules/receipt/helpers';
import { playWarehouseSound } from 'warehouse/hooks';
import { receiptActions } from '../receipt.state';
import { receiptApprovementSnackbarDuration } from 'warehouse/modules/receipt/constants';
const tryBlockReceiptItem = (receiptItem) => (dispatch, getState) => {
    if (!receiptItem) {
        const searchTerm = getState().receipt.sharedArticleLocalSearchTerm;
        if (searchTerm.trim().length === 0)
            return;
        // A single snackbar is allowed at one moment. Manually closing existed one, since
        closeSnackbar();
        playWarehouseSound('error');
        enqueueSnackbar({
            key: searchTerm,
            preventDuplicate: true,
            variant: 'receiptSubmissionError',
            article: searchTerm,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            autoHideDuration: receiptApprovementSnackbarDuration,
        });
        dispatch(receiptActions.setSharedArticleLocalSearchTerm(''));
        return;
    }
    const availableQuantity = getReceiptItemAvailableQuantity(receiptItem);
    if (availableQuantity < 1) {
        playWarehouseSound('full');
        return;
    }
    dispatch(receiptActions.openBlockReceiptItemDialog(receiptItem));
};
export { tryBlockReceiptItem };
