import { createSlice } from '@reduxjs/toolkit';
import { PlacedPlacementTableGrouping, ReceiptedPlacementTableGrouping } from '../models';
const initialState = {
    receiptedIsBlocked: false,
    receiptedSearchTerm: '',
    receiptedGrouping: ReceiptedPlacementTableGrouping.None,
    receiptedItemCount: undefined,
    placedIsBlocked: false,
    placedSearchTerm: '',
    placedGrouping: PlacedPlacementTableGrouping.None,
    placedItemCount: undefined,
    blockPlacementItemDialog: {
        open: false,
        data: undefined,
    },
    assignPlaceDialog: {
        open: false,
        data: undefined,
    },
};
const slice = createSlice({
    name: 'placement',
    initialState,
    reducers: {
        setReceiptedIsBlocked: (state, action) => {
            state.receiptedIsBlocked = action.payload;
        },
        setReceiptedSearchTerm: (state, action) => {
            state.receiptedSearchTerm = action.payload;
        },
        setReceiptedGrouping: (state, action) => {
            state.receiptedGrouping = action.payload;
        },
        setReceiptedItemCount: (state, action) => {
            state.receiptedItemCount = action.payload;
        },
        setPlacedIsBlocked: (state, action) => {
            state.placedIsBlocked = action.payload;
        },
        setPlacedSearchTerm: (state, action) => {
            state.placedSearchTerm = action.payload;
        },
        setPlacedGrouping: (state, action) => {
            state.placedGrouping = action.payload;
        },
        setPlacedItemCount: (state, action) => {
            state.placedItemCount = action.payload;
        },
        openBlockPlacementItemDialog: (state, action) => {
            state.blockPlacementItemDialog = {
                open: true,
                data: action.payload,
            };
        },
        closeBlockPlacementItemDialog: (state) => {
            state.blockPlacementItemDialog = initialState.blockPlacementItemDialog;
        },
        openAssignPlaceDialog: (state, action) => {
            state.assignPlaceDialog = {
                open: true,
                data: action.payload,
            };
        },
        closeAssignPlaceDialog: (state) => {
            state.assignPlaceDialog = initialState.assignPlaceDialog;
        },
    },
});
export const { actions: placementActions, reducer: placementStateReducer } = slice;
