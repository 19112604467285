export const getConfirmFieldsFromReceiptItem = (receiptItem) => {
    const { receiptItemId, article, brand, invoiceId, name, userName } = receiptItem;
    const confirmItem = {
        receiptItemId,
        article,
        brand,
        invoiceId,
        name,
        userName,
        isBlocked: false,
    };
    return confirmItem;
};
