import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, LinearProgress, Typography } from '@mui/material';
export const LinearProgressWithLabel = ({ value, size = 'small', maxWidth, overflowRed, color, ...props }) => {
    const height = size === 'extraSmall'
        ? '.25rem'
        : size === 'small'
            ? '.5rem'
            : size === 'medium'
                ? '.75rem'
                : size === 'large'
                    ? '1rem'
                    : size === 'extraLarge'
                        ? '1.25rem'
                        : undefined;
    const barColor = overflowRed && value > 100 ? 'error' : color;
    return (_jsxs(Box, { display: "flex", alignItems: "center", flex: 1, maxWidth: maxWidth, children: [_jsx(Box, { width: "100%", marginRight: 2, children: _jsx(LinearProgress, { variant: "determinate", ...props, value: value > 100 ? 100 : value, color: barColor, sx: { height } }) }), _jsx(Box, { minWidth: 35, children: _jsx(Typography, { variant: "body2", color: "text.secondary", children: `${Math.round(value)}%` }) })] }));
};
