import { faBarcode, faBoxOpen, faBoxesPacking, faBoxesStacked, faCubesStacked, faDolly, faGear } from '@fortawesome/free-solid-svg-icons';
import { t } from 'shared/translations';
export const sidebarNavigationItems = [
    {
        sectionName: t('crossdocking.label'),
        menuItems: [
            {
                title: t('receipt.label'),
                linkTo: '/receipt/cross',
                icon: faBarcode,
            },
            {
                title: t('placement.label'),
                linkTo: '/placement',
                icon: faBoxesPacking, // originally fa-arrow-down-arrow-up (only in pro).
            },
            {
                title: t('picking.label'),
                linkTo: '/picking',
                icon: faBoxOpen,
            },
            {
                title: t('shipment.label'),
                linkTo: '/shipment',
                icon: faDolly, // TODO: add and use faForklift icon !!!!!
            },
        ],
    },
    {
        sectionName: t('stock.label'),
        menuItems: [
            {
                title: t('receipt.label'),
                linkTo: '/receipt/stock',
                icon: faBarcode,
            },
            {
                title: t('warehouses.label'),
                linkTo: 'stock',
                icon: faBoxesStacked, // originally fa-arrow-down-arrow-up (only in pro).
            },
            {
                title: t('warehouse.addresses.label'),
                linkTo: '/storage',
                icon: faCubesStacked, // use faGoalNet from pro
            },
        ],
    },
    {
        sectionName: t('settings.label'),
        menuItems: [
            {
                title: t('settings.label'),
                linkTo: '/settings',
                icon: faGear,
            },
        ],
    },
];
