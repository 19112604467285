import * as yup from 'yup';
import { emailRegex, priceListLogoRegex } from './validationRegex';
import { highestTcpPortNumber, smallestTcpPortNumber } from 'shared/constants';
import { LegalType } from 'admin/modules/customers/models';
import { t } from 'shared/translations';
//import { AnyObject, Maybe } from 'yup/lib/types';
// TODO: get rid of? Use (following) string().email() instead.
export const strongEmail = yup.string().required().matches(emailRegex, 'Invalid email address');
yup.addMethod(yup.string, 'email', function validateEmail(message) {
    return this.matches(emailRegex, {
        message: message ?? t('invalid.email.address'),
        name: 'email',
        excludeEmptyString: true,
    });
});
// // Using custom test method
// const tcpIpPort = (message: string) => {
//   return this.test("isValidCountry", message, function (value) {
//     const { path, createError } = this;
//     if (!value) {
//       return createError({ path, message: message ?? REQUIRED_ERROR_MESSAGE });
//     }
//     if (value.length < 3) {
//       return createError({ path, message: message ?? TOO_SMALL_ERROR_MESSAGE });
//     }
//     if (!value.match(COUNTRY_NAME_REGEX)) {
//       return createError({
//         path,
//         message: message ?? INVALID_FORMAT_ERROR_MESSAGE
//       });
//     }
//     return true;
//   });
// }
yup.addMethod(yup.string, 'tcpIpPortAsString', function validateTcpIpPort(message) {
    return this.test('isValidTcpIpPortAsString', message ?? t('invalid.tcp.ip.port'), function (value) {
        const { path, createError } = this;
        // Optional case.
        if (!value) {
            return true;
        }
        if (value.length > 5) {
            return createError({ path, message: message ?? t('invalid.tcp.ip.port') });
        }
        const numericValue = Number.parseInt(value);
        if (Number.isNaN(+numericValue) || numericValue > highestTcpPortNumber || numericValue < smallestTcpPortNumber) {
            return createError({
                path,
                message: message ?? t('invalid.tcp.ip.port'),
            });
        }
        return true;
    });
});
yup.addMethod(yup.number, 'tcpIpPortAsNumber', function validateTcpIpPort(message) {
    return this.test('isValidTcpIpPortAsNumber', message ?? t('invalid.tcp.ip.port'), function (value) {
        const { path, createError } = this;
        // Optional case.
        if (value === undefined || value === null) {
            return true;
        }
        if (value > highestTcpPortNumber || value < smallestTcpPortNumber) {
            return createError({
                path,
                message: message ?? t('invalid.tcp.ip.port'),
            });
        }
        return true;
    });
});
yup.addMethod(yup.string, 'priceListLogo', function validatePriceListLogo(message) {
    return this.matches(priceListLogoRegex, {
        message: message ?? t('invalid.price.list.logo.string'),
        name: 'priceListLogo',
        excludeEmptyString: true,
    });
});
export const bik = yup
    .string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    //.min(9, 'Must be exactly 9 digits')
    //.max(9, 'Must be exactly 9 digits'),
    .max(9, 'Must be maximum 9 digits');
export const inn = yup
    .string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    //.min(12, 'Must be exactly 12 digits')
    //.max(12, 'Must be exactly 12 digits'),
    .max(12, 'Must be maximum 12 digits');
export const paymentAccount = yup
    .string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    //.min(20, 'Must be exactly 20 digits')
    //.max(20, 'Must be exactly 20 digits'),
    .max(20, 'Must be maximum 20 digits');
export const kppRequired = yup.string().when('legalTypeId', {
    is: LegalType.IP,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required('KPP is required.'),
});
// addMethod(date, 'format', function format(formats, parseStrict) {
//   return this.transform((value, originalValue, ctx) => {
//     if (ctx.isType(value)) return value;
//     value = Moment(originalValue, formats, parseStrict);
//     return value.isValid() ? value.toDate() : new Date('');
//   });
// });
// yup.addMethod<yup.StringSchema>(yup.string, "emptyAsUndefined", function () {
//   return this.transform((value) => (value ? value : undefined));
// });
// yup.addMethod<yup.NumberSchema>(yup.number, "emptyAsUndefined", function () {
//   return this.transform((value, originalValue) =>
//     String(originalValue)?.trim() ? value : undefined
//   );
// });
// declare module "yup" {
//   interface StringSchema<
//     TType extends Maybe<string> = string | undefined,
//     TContext extends AnyObject = AnyObject,
//     TOut extends TType = TType
//   > extends yup.BaseSchema<TType, TContext, TOut> {
//     emptyAsUndefined(): StringSchema<TType, TContext>;
//   }
//   interface NumberSchema<
//     TType extends Maybe<number> = number | undefined,
//     TContext extends AnyObject = AnyObject,
//     TOut extends TType = TType
//   > extends yup.BaseSchema<TType, TContext, TOut> {
//     emptyAsUndefined(): NumberSchema<TType, TContext>;
//   }
// }
// declare module 'yup' {
//   interface StringSchema<TType, TContext, TDefault, TFlags> {
//     append(appendStr: string): this;
//   }
// }
// export default yup;
export { yup };
