import { useLocalStorageBase } from 'core/hooks';
/**
 * Hook that allows to use *localStorage* for **Warehouse** application.
 *
 * @template T - The type of the state to be stored in local storage.
 * @param {WarehouseAppLocalStorageKey} key - The key under which the value will be stored in local storage.
 * @param {T | (() => T)} initialValue - The initial value of the state or a function that returns the initial value.
 * @returns {[T, Dispatch<SetStateAction<T>>, () => void]} A tuple containing the *stored value*, a *function to set the value* and a *function to remove the key* from storage.
 * @example
 * ```tsx
 * // Access the `count` value, the `setCount` function to update it and `removeCount` function to remove the key from storage.
 * const [count, setCount, removeCount] = useAppLocalStorage({ key:'count', initialValue: 0 });
 * // Acts like a native useState from react.
 * setCount(prevValue => prevValue + 1)
 * ```
 */
export const useAppLocalStorage = ({ key, initialValue }) => useLocalStorageBase(key, initialValue);
